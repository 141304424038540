// @ts-nocheck
import React, { useState, memo, useMemo } from "react";
import { useMutation } from "react-query";
import Image from "next/image";

import { CustomInput, ModalForm } from "components";

import styles from "./GiveModal.module.scss";

import { isEmailValid, isNameValid, isPhoneValid } from "src/utils/validation";
import { basePostRequest } from "utils/api/api";
import { sertificateUrl, saveClientUrl } from "utils/api/requestUrls";

interface GiveModalProps {
  isOpen: boolean;
  setOpen: any;
  title: string;
  id: string;
  setIsSuccessSertificate: any;
  questGiveData: any;
}

export default memo(function GiveModal(props: GiveModalProps) {
  const {
    isOpen,
    setOpen,
    title,
    id,
    setIsSuccessSertificate,
    subTitle,
    questGiveData,
  } = props;

  const pageView = (event) => {
    window &&
      window.dataLayer &&
      window.dataLayer.push({
        event: event,
      });
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    // id,
  });

  const handleFieldsChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const { name, email, phoneNumber } = formData;

  const { mutate: saveGiveForm, isLoading: saveGiveFormLoading } = useMutation(
    (body) => basePostRequest(sertificateUrl, body),
    {
      onSuccess: () => {
        pageView("certificate_form_send");
        setOpen(false);
        setIsSuccessSertificate(true);
      },
      onError: (err) => {
        console.warn("error", err);
      },
    }
  );

  const { mutate: saveClientSubmit, isLoading: saveClientLoading } =
    useMutation(() => basePostRequest(saveClientUrl, formData), {
      onSuccess: (res) => {
        const body = {
          applied: false,
          approved: false,
          fromClient: res,
          id: null,
          price: 100,
        };
        saveGiveForm(body);
      },
      onError: (err) => {
        console.warn("error", err);
      },
    });

  const isFormValid = useMemo(
    () => isEmailValid(email) && isNameValid(name) && isPhoneValid(phoneNumber),
    [name, email, phoneNumber]
  );

  return (
    <div>
      <ModalForm
        {...{
          modalTitle: title,
          isOpen,
          setOpen,
          onSubmit: saveClientSubmit,
          isFormInvalid: !isFormValid,
          submitButtonLabel: "Отправить заявку",
          modalWidth: "sm",
          subTitle,
          isShowPrivacyPolice: true,
        }}
      >
        <div className={styles.Title}>Оформить подарочный сертификат</div>
        <div className={styles.Description}>
          Оставьте заявку на приобритение подарочного сертификата и мы свяжемся с вами в ближайшее время!
        </div>
        <div className={styles.QuestCard}>
          <div className={styles.Image}>
            <Image
              className={styles.MainQuestImage}
              src={questGiveData?.imageFilePath}
              loader={() => questGiveData?.imageFilePath}
              alt={questGiveData?.imageFilePath}
              width={140}
              height={140}
            />
          </div>
          <div className={styles.Block2}>
            <div className={styles.QuesInfoWrapper}>
              <div className={styles.QuestName}>{questGiveData?.name}</div>
              <div className={styles.QuestAddress}>
                <Image
                  src="/icons/address.svg"
                  alt="address icon"
                  width={"20"}
                  height={"20"}
                />
                <span>{questGiveData?.address}</span>
              </div>

              <div className={styles.CardQuestInfo}>
                <div className={styles.CardQuestInfoBlock}>
                  <div className={styles.CardQuestInfoBlockItem}>
                    <Image
                      src="/icons/clock.svg"
                      alt="address icon"
                      width={"20"}
                      height={"20"}
                    />
                    <span> {questGiveData?.duration}</span>
                  </div>
                  <div className={styles.CardQuestInfoBlockItem}>
                    <Image
                      src="/icons/people.svg"
                      alt="address icon"
                      width={"20"}
                      height={"20"}
                    />
                    <span>{questGiveData?.countOfPlayersInfo} человек</span>
                  </div>
                </div>

                <div className={styles.CardQuestInfoBlock}>
                  <div className={styles.CardQuestInfoBlockItem}>
                    <Image
                      src="/icons/age.svg"
                      alt="address icon"
                      width={"20"}
                      height={"20"}
                    />
                    <span>
                      от{" "}
                      {questGiveData?.ageInfo.replace('+', '')}{" "}
                      лет
                    </span>
                  </div>
                  <div className={styles.CardQuestInfoBlockItem}>
                    <Image
                      src="/icons/horror.svg"
                      alt="address icon"
                      width={20}
                      height={20}
                    />
                    <span>
                      {questGiveData?.withActors ? "С актером" : "Без актера"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={"flex flex-shrink-0 flex-col justify-between w-full mt-4"}
        >
          <CustomInput
            {...{
              type: "name",
              label: "Имя",
              name: "name",
              // isInvalid: nameError,
              // setIsInvalid: ( value, ) => setErrorState.update( { nameError: value, }, ),
              isValid: isNameValid,
              helperText: "Поле не должно оставаться пустым",
              onChange: handleFieldsChange,
            }}
          />
          <div className={styles.InputRow}>
            <CustomInput
              {...{
                type: "phone",
                label: "Телефон",
                name: "phoneNumber",
                // isInvalid: phoneError,
                // setIsInvalid: ( value, ) => setErrorState.update( { phoneError: value, }, ),
                isValid: isPhoneValid,
                helperText: "Неверный формат. Пример +375291234567",
                onChange: handleFieldsChange,
              }}
            />
            <CustomInput
              {...{
                type: "email",
                label: "Email",
                name: "email",
                // isInvalid: emailError,
                // setIsInvalid: ( value, ) => setErrorState.update( { emailError: value, }, ),
                isValid: isEmailValid,
                helperText: "Неверный формат ввода адреса почты",
                onChange: handleFieldsChange,
              }}
            />
          </div>
        </div>
      </ModalForm>
    </div>
  );
});
