//@ts-nocheck

import React, { useState, memo, useCallback, useEffect, useMemo } from "react";
import { useMutation } from "react-query";
import { useRouter } from "next/router";
import classNames from "classnames";

import styles from "./QuizListContainer.module.scss";
import GiveModal from "modals/GiveModal";
import SuccessModal from "modals/SuccessModal";

import { baseGetRequest } from "utils/api/api";
import { quizListUrl } from "utils/api/requestUrls";

import {
  PageWrapper,
  TextComponent,
  QuizCard,
  MainPageFilters,
  HtmlRenderer,
} from "components";

import { translit } from "utils";
import _ from "lodash";

const QuizListContainer = (props: any) => {
  const {
    genresText,
    questListFirstData,
    genresFirstData,
    raitingFirstData,
    activeCategoryId = null,
  } = props;

  const router = useRouter();
  const [isGiveModalOpen, setIsGiveModalOpen] = useState(false);
  const [questGiveData, setQuestGiveData] = useState([]);
  const [isSuccessSertificate, setIsSuccessSertificate] = useState(false);

  const [questData, setQuestData] = useState(questListFirstData);
  
  const [playersCount, setPlayersCount] = useState(null);
  const [withActor, setWithActor] = useState(null);

  const onGiveClick = (e, id) => {
    const quest = questListFirstData.filter((item) => item.id === id);
    setQuestGiveData(quest?.[0]);
    e.stopPropagation();
    e.preventDefault();
    setIsGiveModalOpen(true);
  };

  const onCategoryClick = useCallback(
    _.debounce((id) => {
      genresFirstData.map((item) => {
        if (item.id === id) {
          router.push({
            pathname: `/kvesty/${translit(item.genre)}`,
            //@ts-ignore
            shallow: true,
          });
        }
      });
    }, 600),
    [router]
  );

  const { mutate: getQuestData, isLoading: isQuestDataLoading } = useMutation(
    () => baseGetRequest(quizListUrl(activeCategoryId, "")),
    {
      onSuccess: (res) => {
        const shuffledArr = res?.content.sort(function () {
          return Math.random() - 0.5;
        });
        const sortedArr = shuffledArr.sort(
          (a, b) => a.progressStatus - b.progressStatus
        );

        setQuestData(sortedArr);
      },
      onError: (err) => {
        console.warn("error", err);
      },
    }
  );

  useEffect(() => {
    getQuestData();
  }, [activeCategoryId]);

  const handleSetItem = (item) => {
    if (typeof item.id === "number") {
      if (item.value === "Любой") {
        router.push("/");
      } else {
        onCategoryClick(item.id);
      }
    }

    if (item.id === "playersCountArr") {
      if (item.value === "Любое") {
        setPlayersCount(null);
      } else {
        setPlayersCount(item.value);
      }
    }

    if (item.id === "actorArr") {
      if (item.value === "С актером") {
        setWithActor(true);
      } else if (item.value === "Без актера") {
        setWithActor(false);
      } else if (item.value === "Неважно") {
        setWithActor(null);
      }
    }
  };

  const questDataByFilter = useMemo(
    () => {
      if (withActor === null && playersCount === null) {
        const filtered = questListFirstData.sort((a, b) => a.progressStatus - b.progressStatus);
        return filtered
      } else {
        return questListFirstData.filter((elem) => {
          const el = elem?.countOfPlayersInfo?.split("-");
  
          if (playersCount !== null && withActor !== null) {
            return (
              Number(el[0]) <= Number(playersCount) &&
              Number(el[1]) >= Number(playersCount) &&
              elem.withActors == withActor
            );
          } else {
            if (withActor !== null) {
              return elem.withActors == withActor;
            }
  
            if (playersCount !== null) {
              return (
                Number(el[0]) <= Number(playersCount) &&
                Number(el[1]) >= Number(playersCount)
              );
            }
          }
        }).sort((a, b) => a.progressStatus - b.progressStatus);
      }
    },
    [playersCount, withActor]
  );

  useEffect(() => {
    setQuestData(questDataByFilter);
  }, [questDataByFilter]);

  return (
    <PageWrapper>
      {!genresText ? (
        <TextComponent
          page="main-page"
          title="Лучшие квесты в Минске!"
          descriptionTexts="Old Mouse — это возможность погрузиться в атмосферу любимых сюжетов.
          Окажитесь на месте героев и попытайтесь выбраться из запертой комнаты.
          Вам и вашим друзьям придется разгадывать головоломки, проявлять
          изобретательность и умение найти выход из любой ситуации."
        />
      ) : (
        <HtmlRenderer {...props} />
      )}

      <MainPageFilters
        genresFirstData={genresFirstData}
        onCategoryClick={onCategoryClick}
        setItem={handleSetItem}
        playersCount={playersCount}
        withActor={withActor}
        activeGenre={genresFirstData.filter(
          (item) => translit(item.genre) === router?.query?.filterid
        )}
      />

      <QuizCard
        dataRaiting={raitingFirstData}
        quizListData={questData}
        onGiveClick={onGiveClick}
      />
      
      {isGiveModalOpen && (
        <GiveModal
          setOpen={setIsGiveModalOpen}
          isOpen={true}
          questGiveData={questGiveData}
          setIsSuccessSertificate={setIsSuccessSertificate}
        />
      )}
      {isSuccessSertificate && (
        <SuccessModal
          title="Заявка на сертификат отправлена"
          description="В ближайшее время с вами свяжется наш менеджер для уточненияинформации"
          setOpen={setIsSuccessSertificate}
          isOpen={true}
        />
      )}
    </PageWrapper>
  );
};

export default memo(QuizListContainer);
