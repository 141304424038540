// @ts-nocheck
import React, { memo, useMemo } from "react";
import { ModalForm } from "components";
import Image from "next/image";

interface GiveModalProps {
  isOpen: boolean;
  setOpen: any;
  title: string;
  icon: any;
  description: string;
  onSubmit: any;
  isFormInvalid: any;
  setIsCaptchaInvalid: any;
  submitButtonLabel: any;
}

export default memo(function SuccessModal(props: GiveModalProps) {
  const { isOpen, setOpen, title, icon, description } = props;

  return (
    <div>
      <ModalForm
        {...{
          modalTitle: title,
          isOpen,
          setOpen,
          modalWidth: "sm",
        }}
      >
        <div className="flex item-center justify-center mb-5">
          <Image
            src={"/icons/successNew.svg"}
            alt="success"
            width={70}
            height={70}
          />
        </div>
        <div className={"w-full px-5 text-center mb-5"}>
         {title}
        </div>
        <div className={"w-full px-5 text-center mb-5"}>
          {description}
        </div>
      </ModalForm>
    </div>
  );
});
